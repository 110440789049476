import { Controller } from '@hotwired/stimulus';
import displayFlash from "../utils/displayFlash";

export default class extends Controller {
  connect() {
    this.element.addEventListener('ajax:success', (event) => {
      const [data] = event.detail;
      if (data.flash) {
        displayFlash(data.flash);
      }
    });
  }
}
