import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    timeout: Number
  };

  connect() {
    const closeButton = this.element.children[this.element.children.length - 1];
    const hasVisibleCloseButton = window.getComputedStyle(closeButton).display !== 'none';

    if (!hasVisibleCloseButton) {
      this.timeout = setTimeout(this.hideFlash.bind(this), this.timeoutValue);
    }
  }

  close() {
    this.hideFlash();
    clearTimeout(this.timeout);
  }

  hideFlash() {
    this.element.style.display = "none";
  }
}
