import { Controller } from "@hotwired/stimulus"
import bulkCancellation from '../bulkCancellation';

export default class extends Controller {
  static values = {
    object: Object,
    hint: String
  };

  initialize() {
    this.committedDay = this.objectValue;
  }

  connect() {
    const parent = document.querySelector('#cancel-coaching-days-modal');
    const committedDays = [this.committedDay];
    this.operation = bulkCancellation(parent, committedDays);
  }

  cancel(event) {
    event.preventDefault();
    this.operation.cancel();
  }
}
