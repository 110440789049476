import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "submit" ];

  connect() {
    this.setSubmitState();
    this.element.addEventListener("ajax:beforeSend", function(event){
      if ( this.submitTarget.classList.contains('--disabled') ){
        event.preventDefault();
      }
    }.bind(this));
  }

  hasSelectedOptions() {
    return this.selectedOptions().length > 0;
  }

  selectedOptions() {
    return this.element.querySelectorAll('input[type="checkbox"]:checked');
  }

  setSubmitState(){
    if ( this.hasSelectedOptions() ){
      this.submitTarget.classList.remove('--disabled');
    } else {
      this.submitTarget.classList.add('--disabled');
    }
  }
}
