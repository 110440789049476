import { Controller } from "@hotwired/stimulus"
import { openBasicModal, closeBasicModal } from '../modal';

export default class extends Controller {
  static values = {
    modal: String
  };

  open(event) {
    event.preventDefault();
    openBasicModal(event.target.dataset.id);
  }

  close(event) {
    closeBasicModal();
  }
}
