import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'sessionCard',
    'sessionTitle',
    'launchSessionLink',
    'tooltip',
    'launchPageContentWrapper',
    'managerQuestionsWrapper'
  ];

  static values = {
    zoomUrl: String,
    appointmentLive: Boolean,
    appointmentOpensAt: String,
    postLaunchUrl: String,
    sessionLaunchedEvent: String,
    tooltipOpenEvent: String,
  };

  connect() {
    if (this.appointmentLiveValue) {
      this.sessionOpenTitle = this.sessionTitleTarget.dataset.sessionOpenTitle;
      this.startTimer();
    }

    this.managerQuestionsWrapperTarget.addEventListener('ajax:complete', () => {
      this.hideManagerQuestions();
    });
  }

  disconnect() {
    clearInterval(this.timer);
  }

  startTimer() {
    if (this.launchSessionLinkTarget.classList.contains('--disabled')) {
      this.timer = setInterval(() => {
        this.checkSessionLink();
      }, 10000);
    }
  }

  checkSessionLink() {
    if (Date.now() >= Date.parse(this.appointmentOpensAtValue)) {
      this.sessionTitleTarget.innerHTML = this.sessionOpenTitle;
      this.enableSessionLink();
    }
  }

  enableSessionLink() {
    this.launchSessionLinkTarget.classList.remove('--disabled');
    clearInterval(this.timer);
  }

  ctaButtonClick() {
    if (this.launchSessionLinkTarget.classList.contains('--disabled')) {
      this.toggleTooltip();
      return;
    }
    this.openZoomUrl();
  }

  toggleTooltip() {
    clearTimeout(this.timeout);
    this.tooltipTarget.classList.toggle('tooltip-show');
    this.trackTooltipOpened();
    this.timeout = setTimeout(() => {
      this.tooltipTarget.classList.remove('tooltip-show');
    }, 5000);
  }

  openZoomUrl() {
    const zoomWindow = window.open(this.zoomUrlValue, 'Your Coaching Session');
    this.trackSessionLaunched();
    // Safari iOS (and others?) won't open new window if the popups are blocked
    if (zoomWindow) {
      zoomWindow.focus();
      this.redirectToPostLaunchUrl();
    } else {
      window.location = this.zoomUrlValue;
    }
  }

  trackSessionLaunched() {
    const eventName = this.sessionLaunchedEventValue;
    this.trackEvent(eventName);
  }

  redirectToPostLaunchUrl() {
    window.location = this.postLaunchUrlValue;
  }

  trackTooltipOpened() {
    const eventName = this.tooltipOpenEventValue;
    this.trackEvent(eventName);
  }

  trackEvent(eventName) {
    try {
      mixpanel.track(eventName, { time: Date.now() / 1000 });
    } catch (error) {
      console.error('Error sending tracking event');
    }
  }

  hideManagerQuestions() {
    this.managerQuestionsWrapperTarget.classList.add('hidden');
    this.launchPageContentWrapperTarget.classList.remove('hidden');
  }
}
