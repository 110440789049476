import { Controller } from '@hotwired/stimulus';
// eslint-disable-next-line import/no-unresolved
import { FrameElement } from "@hotwired/turbo/dist/types/elements";

export default class extends Controller {
  static targets = ['input'];

  declare inputTarget: HTMLInputElement;
  declare boundTriggerBlurOnInput: (event: KeyboardEvent) => void;
  declare boundHideForm: (event: Event) => void;
  declare boundRemoveEventListeners: (event: Event) => void;
  declare boundRedirectOnError: (event: Event) => void;
  declare showPathValue: string;

  static values = {
    showPath: String
  }

  connect(){
    this.highlightSelection();
    this.addEventListeners();
  }

  disconnect() {
    this.removeEventListeners();
  }

  addEventListeners(){
    this.boundTriggerBlurOnInput = this.triggerBlurOnInput.bind(this);
    document.addEventListener('keyup', this.boundTriggerBlurOnInput);

    this.boundHideForm = this.hideForm.bind(this);
    this.inputTarget.addEventListener('blur', this.boundHideForm);

    this.boundRemoveEventListeners = this.removeEventListeners.bind(this);
    this.element.addEventListener('turbo:submit-start', this.boundRemoveEventListeners);

    this.boundRedirectOnError = this.redirectOnError.bind(this);
    this.element.addEventListener('turbo:submit-end', this.boundRedirectOnError);
  }

  removeEventListeners(){
    document.removeEventListener('keyup', this.triggerBlurOnInput);
    this.inputTarget.removeEventListener('blur', this.boundHideForm);
    this.element.removeEventListener('turbo:submit-start', this.boundRemoveEventListeners);
    this.element.removeEventListener('turbo:submit-end', this.boundRedirectOnError);
  }

  highlightSelection(){
    this.inputTarget.type = 'text';
    this.inputTarget.setSelectionRange(0, this.inputTarget.value.length);
    this.inputTarget.type = 'number';
  }

  triggerBlurOnInput(e: KeyboardEvent) {
    if (e.key === "Escape") {
      this.inputTarget.dispatchEvent(new Event('blur', { 'bubbles': true }));
    }
  }

  hideForm(){
    (this.element.parentElement as FrameElement).src = this.showPathValue;
    this.removeEventListeners();
  }

  redirectOnError(e: Event) {
    if ((e as CustomEvent).detail.success === false) window.location.reload();
  }
}
