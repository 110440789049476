const CONTROLS_SELECTOR = '[data-glide-el^="controls"]';
const PREVIOUS_CONTROLS_SELECTOR = `${CONTROLS_SELECTOR} [data-glide-dir*="<"]`;
const NEXT_CONTROLS_SELECTOR = `${CONTROLS_SELECTOR} [data-glide-dir*=">"]`;

// eslint-disable-next-line func-names
export default function (Glide, Components, Events) {
  const ArrowState = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    mount () {},

    setArrowState () {
      if (Glide.settings.rewind) {
        return
      }

      const next = Components.Html.root.querySelectorAll(NEXT_CONTROLS_SELECTOR);
      const previous = Components.Html.root.querySelectorAll(PREVIOUS_CONTROLS_SELECTOR);

      this.resetArrowState(next, previous);

      const isSliderStart = Glide.index === 0
      const isSliderEnd =
        Glide.settings.perView + Glide.index - 1 >= Components.Run.length;

      if (isSliderStart) {
        this.disableArrow(previous)
      }

      if (isSliderEnd) {
        this.disableArrow(next)
      }
    },

    resetArrowState (...lists) {
      const {settings} = Glide;

      lists.forEach((list) => {
        list.forEach((element) => {
          element.classList.remove(settings.classes.disabledArrow)
        })
      })
    },

    disableArrow (...lists) {
      const {settings} = Glide;

      lists.forEach((list) => {
        list.forEach((element) => {
          element.classList.add(settings.classes.disabledArrow)
        })
      })
    }
  };

  Events.on(['mount.after', 'run'], () => {
    ArrowState.setArrowState();
  });

  return ArrowState
}
