import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    modalid: String
  };

  open(e) {
    document.querySelector(`#${e.target.dataset.identifier}`).modal.open();
  };
}
