import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "options" ];

  connect() {
    if ( !this.hasSelectedOptions() ){
      this.optionsTarget.style.display = "none";
    }
  }

  showOptions() {
    const display = this.optionsTarget.style.display === 'none' ? 'block' : 'none';
    this.optionsTarget.style.display = display;
  }

  hasSelectedOptions() {
    const options = this.selectedOptions();
    return options.length > 0;
  }

  selectedOptions() {
    return document.querySelectorAll('input[type="checkbox"]:checked');
  }
}
