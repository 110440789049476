import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  open(event) {
    event.preventDefault();
    this.closeOtherContextMenus();
    this.element.classList.toggle('--active');
    this.setMenuListPosition();
  }

  closeAllContextMenus() {
    const menus = document.querySelectorAll('.context-menu');
    menus.forEach((menu) => {
      menu.classList.remove('--active');
    });
  }

  closeOtherContextMenus() {
    const menus = document.querySelectorAll('.context-menu');
    menus.forEach((menu) => {
      if ( menu !== this.element) {
        menu.classList.remove('--active');
      }
    });
  }

  setMenuListPosition() {
    const menuList = this.element.querySelector('.menu-list');
    const top = (menuList.offsetHeight / 2) - 7;
    menuList.style.top = `-${top}px`;
  }
}
