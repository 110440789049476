import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.offset = this.element.offsetTop;
    this.calendar = document.querySelector('.coach-profiles-cta');
    if ( window.innerWidth < 800 ){
      window.onscroll = this.stickElement.bind(this);
    }
  }

  stickElement() {
    if (window.pageYOffset > this.offset) {
      this.element.classList.add("sticky");
      this.calendar.classList.add("stuck");
    } else {
      this.element.classList.remove("sticky");
      this.calendar.classList.remove("stuck");
    }
  }
}
