import { Controller } from '@hotwired/stimulus';
import axios from '../utils/axios';

interface OfficeDates {
  reportStartDate: string;
  reportEndDate: string;
  officeStartDate?: string;
}

export default class extends Controller {
  static targets = [
    'officeSelect',
    'dateFields',
    'reportStartDate',
    'reportEndDate'
  ];

  declare officeDatesUrl: string;
  declare officeSelectTarget: HTMLSelectElement;
  declare dateFieldsTarget: HTMLDivElement;
  declare reportStartDateTarget: HTMLInputElement;
  declare reportEndDateTarget: HTMLInputElement;

  initialize() {
    this.officeDatesUrl = '/partner_reports/office_dates/';
  }

  getOfficeDates(e: Event) {
    const officeId = (e.currentTarget as HTMLSelectElement).value;
    if (!officeId) {
      this.hideDateFields();
      return;
    }
    axios(`${this.officeDatesUrl}${officeId}`).then((result) => {
      this.applyDateFieldValues(result.data);
      this.showDateFields();
    });
  }

  hideDateFields() {
    this.dateFieldsTarget.classList.add('hidden');
  }

  showDateFields() {
    this.dateFieldsTarget.classList.remove('hidden');
  }

  applyDateFieldValues(data: OfficeDates) {
    const { reportStartDate, reportEndDate, officeStartDate } = data;
    this.reportStartDateTarget.value = reportStartDate;
    this.reportEndDateTarget.value = reportEndDate;
    if (officeStartDate) {
      this.reportStartDateTarget.setAttribute('min', officeStartDate);
    }
  }
}
