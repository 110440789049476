import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const container = this.element;

    $('.coach-profile:not(:first)').hide();

    $(container).find('.coach-image').on('click', function(){
      $('.coach-profile').hide();
      $('#coach-profile-' + $(this).data('coach-id')).css( { display: 'grid' } );
    });
  }
}
