import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.inputFieldsToClear = this.element.querySelectorAll(
      'input:not([type="submit"]):not([type="hidden"]):not([class*="no-clear"])',
    );
    this.selectFieldsToClear = this.element.querySelectorAll('select');
    this.errorDisplay = this.element.querySelector('#error_explanation');
  }

  reset() {
    [...this.inputFieldsToClear, ...this.selectFieldsToClear].forEach((field) => {
      field.value = '';

      if (field.tomselect) {
        field.tomselect.clear();
      }
    });

    if (this.errorDisplay) {
      this.errorDisplay.style.display = 'none';
    }
  }
}
