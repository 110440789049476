import { Controller } from '@hotwired/stimulus';
import { createWidget } from '@typeform/embed';
import '@typeform/embed/build/css/widget.css';
import displayFlash from '../utils/displayFlash';

export default class extends Controller {
  static values = {
    formId: String,
    uuid: String,
  };

  static targets = ['introContainer', 'formContainer'];

  declare formIdValue: string;
  declare uuidValue: string;
  declare introContainerTarget: HTMLDivElement;
  declare formContainerTarget: HTMLDivElement;

  initForm() {
    this.formContainerTarget.classList.remove('hidden');
    createWidget(this.formIdValue, {
      container: this.formContainerTarget,
      inlineOnMobile: true,
      hideHeaders: true,
      hidden: {
        uuid: this.uuidValue,
      },
      onSubmit: () => {
        displayFlash('Your session has been confirmed. You may close this window');
      },
    });
  }

  hideHeader() {
    // I know it's bit horrible but...
    document.querySelector('header')?.classList.add('!hidden');
  }

  hideIntroContainer() {
    this.introContainerTarget.classList.add('hidden');
  }

  handleCtaClick() {
    this.hideHeader();
    this.hideIntroContainer();
    this.initForm();
  }
}
