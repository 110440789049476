import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkBox'];

  declare checkBoxTargets: HTMLInputElement[];

  connect() {
    document.addEventListener('keydown', this.nextPage);
  }

  disconnect() {
    document.removeEventListener('keydown', this.nextPage);
  }

  updateSingleSelectCheckBoxes(event: Event) {
    const clickedCheckBox = event.currentTarget as HTMLInputElement;
    const otherCheckBoxes = this.checkBoxTargets.filter(checkBox => checkBox !== clickedCheckBox);

    if (clickedCheckBox.checked) {
      otherCheckBoxes.forEach(checkBox => checkBox.checked = false);
    }
  };

  updateSingleSelectCheckButtons(event: Event) {
    const clickedButton = event.currentTarget as HTMLElement;
    const clickedLabel = clickedButton.nextElementSibling as HTMLElement;
    const checkbox = clickedButton.children[1] as HTMLInputElement;
    const otherButtons = this.checkBoxTargets.filter(x => x !== clickedButton);

    checkbox.checked = true;
    clickedLabel.classList.add("selected");
    clickedButton.classList.add("selected");

    otherButtons.forEach(btn => {
      const label = btn.nextElementSibling as HTMLElement;
      const checkbox = btn.children[1] as HTMLInputElement;

      label.classList.remove("selected");
      btn.classList.remove("selected");
      checkbox.checked = false;
    });

    const button = document.getElementById("multi-page-form-button-next") ||
      document.getElementById("multi-page-form-button-start");
    if (button == null) return;

    button.click();
  };

  nextPage(event: KeyboardEvent) {
    if (event.key != 'Enter') return;
    if (event.repeat) return;

    // Ignore event if a specific page element is selected
    // e.g. a button, or a textarea field
    if ((event.target as HTMLInputElement).type !== undefined) return;

    // Ignore the event if there is no 'start/next' button on the page
    // (excludes the form's final 'submit' button)
    const button = document.getElementById("multi-page-form-button-next") ||
      document.getElementById("multi-page-form-button-start");
    if (button == null) return;
    button.click();
  };
};
