import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("ajax:success", (event) => {
      if ( event.target.id !== 'reservation-sign-up' ) {
        window.location = gon.urls.post_confirmation;
      }
    });
  }
}
