import { Controller } from "@hotwired/stimulus"
import { bulkRemoval } from '../bulkRemoval';

export default class extends Controller {
  static values = {
    object: Object
  };

  connect() {
    const form = document.querySelector('#remove-committed-day-form');
    const committedDays = [this.objectValue];
    this.operation = bulkRemoval(form, committedDays);
  }

  remove(event) {
    event.preventDefault();
    this.operation.remove();
  }
}
