import { Controller } from '@hotwired/stimulus';
import displayFlash from "../utils/displayFlash";
import copyToClipboard from "../utils/copyToClipboard"

export default class extends Controller {
  static values = {
    toCopy: String,
    notice: String,
  };

  copyValue(e) {
    e.preventDefault();
    const textToCopy = this.toCopyValue;
    copyToClipboard(textToCopy);
    displayFlash(this.noticeValue);
  }
}
