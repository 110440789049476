import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['onboardingSpecificFieldInput', 'onboardingVideoWrapper', 'onboardingPronounInput', 'onboardingPronounTextInput', 'onboardingInterestCheck'];

  declare onboardingVideoWrapperTarget: HTMLDivElement;
  declare onboardingSpecificFieldInputTarget: HTMLSelectElement;
  declare onboardingPronounInputTarget: HTMLSelectElement;
  declare onboardingPronounTextInputTarget: HTMLInputElement;
  declare onboardingInterestCheckTargets: HTMLInputElement[];

  connect() {};

  showVideoToggle(event: Event) {
    const currentSelection = (event.currentTarget as HTMLSelectElement);
    if (currentSelection.selectedIndex === 3) {
      this.onboardingVideoWrapperTarget.classList.remove('hidden');
    } else {
      this.onboardingVideoWrapperTarget.classList.add('hidden');
    }
  };

  showPronounToggle(event: Event) {
    const currentSelection = (event.currentTarget as HTMLSelectElement);
    if (currentSelection.selectedIndex === 4) {
      this.onboardingPronounTextInputTarget.classList.remove('hidden');
      this.onboardingPronounTextInputTarget.required = true;
    } else {
      this.onboardingPronounTextInputTarget.classList.add('hidden');
      this.onboardingPronounTextInputTarget.value = '';
      this.onboardingPronounTextInputTarget.required = false;
    }
  }

  limitInterestChoices(_event: Event) {
    const currentSelection = this.onboardingInterestCheckTargets.filter((box) => box.checked);
    const notSelected = this.onboardingInterestCheckTargets.filter((box) => !box.checked);
    if (currentSelection.length >= 3) {
      notSelected.forEach((box) => {
        box.disabled = true;
        box.classList.add('text-grey-2')
      });
    } else {
      this.onboardingInterestCheckTargets.forEach((box) => {
        box.disabled = false;
        box.classList.remove('text-grey-2')
      });
    }
  }
}
