import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const list = this.element;

    const answers = $(list).find('.faq-answer');

    $(list).find('.faq-answer').slideUp(0);

    $(list).find('.faq-header').on('click', function(){
      $(this).parent().toggleClass('--open');
      $(this).siblings('.faq-answer').slideToggle();
    });

    if ( window.innerWidth > 800 ){
      const firstItem = $(list).find('.faq-header')[0];
      $(firstItem).parent().toggleClass('--open');
      $(firstItem).siblings('.faq-answer').slideToggle();
    }
  }
}
