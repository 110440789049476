import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    until: String
  };

  connect() {
    this.startTimer();
  }

  disconnect() {
    clearInterval(this.timeout);
  }

  startTimer() {
    this.datetime = Date.parse(this.untilValue);
    this.updateCountdown(this.formattedTimeRemaining(this.secondsRemaining()));
    this.timeout = setInterval(() => {
      this.updateCountdown(this.formattedTimeRemaining(this.secondsRemaining()));
    }, 1000);
  }

  updateCountdown(value) {
    this.element.innerHTML = `${  value  }`;
  }

  secondsRemaining() {
    const now = new Date();
    return parseInt((this.datetime - now) / 1000);
  };

  formattedTimeRemaining(secondsRemaining) {
    if ( secondsRemaining > 0 ){
      const seconds = Math.floor( secondsRemaining % 60 ).toString().padStart(2, '0');
      const minutes = Math.floor( (secondsRemaining / 60) % 60 ).toString().padStart(2, '0');
      return `${minutes}:${seconds}`;
    } 
      return "00:00";
    
  };
}
