import { Controller } from "@hotwired/stimulus"
import Glide, { Keyboard, Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'
import ArrowState from "../components/glide/ArrowState";

export default class extends Controller {
  static values = {
    startingIndex: Number
  }

  initialize() {
    this.perView = 3;
    this.width = window.innerWidth;
    this.setupOneTimeHandlers();
  }

  connect() {
    this.setup();
    this.boundHandleEscape = this.handleEscape.bind(this);
    document.addEventListener('keyup', this.boundHandleEscape);
  }

  disconnect() {
    this.tearDown();
    document.removeEventListener('keyup', this.boundHandleEscape);
  }

  setup() {
    this.setupGlide();
    this.setupJumpLinks();
    this.setupFilters();
  }

  setupGlide() {
    const element = this.element.querySelector('.glide');
    this.glide = new Glide(element, {
      perView: this.perView,
      startAt: this.startingIndexValue,
      type: 'slider',
      rewind: false,
      breakpoints: {
        1020: { perView: 1 },
        1270: { perView: 2 } },
    });

    this.glide.on('run.before', (move) => {
      if ( move.direction === '>') {
        move.steps = -this.glide.settings.perView;
      } else if ( move.direction === '<') {
        move.steps = this.glide.settings.perView;
      }
    });

    this.glide.on('resize', () => {
      const width = window.innerWidth;
      const calendarCards = $('.calendar-card.glide__slide');
      if (width <= 768) {
        // Mobile breakpoint
        this.glide.disable();
        // jQuery slides used in mobile_sessions_controller.js
        calendarCards.addClass('--closed');
        $('.day-sessions').slideUp();
      } else {
        // Desktop
        this.glide.enable();
        calendarCards.removeClass('--closed');
        $('.day-sessions').slideDown();
      }
    });

    this.glide.mount( { Controls, Keyboard, Breakpoints, ArrowState });
  }

  setupJumpLinks() {
    const jumpLinks = Array.from(document.querySelectorAll('.jump-link'));
    const calendarCardDates = Array.from(
      document.querySelectorAll('.calendar-card.glide__slide')
    ).map(x => x.dataset.date);

    jumpLinks.forEach((jumpLink) => {
      jumpLink.addEventListener('click', (event) => {
        const dateToLinkTo = event.target?.attributes?.data_link_to_date?.value;
        const calendarCardIndex = calendarCardDates.indexOf(dateToLinkTo);
        if (calendarCardIndex === undefined) return;

        this.glide.go(`=${calendarCardIndex}`);
      });
    });
  }

  setupFilters() {
    if ( window.innerWidth < 768 ){
      document.querySelector('.filters').addEventListener('click', () => {
        document.querySelector('#session-filters').classList.add('--open');
      });
    }

    document.querySelector('.toggle-filters').addEventListener('click', () => {
      this.openSessionFilters();
    });

    document.querySelector('#modal-search-mask').addEventListener('click', (e) => {
      if ( e.target.id === 'modal-search-mask' ) {
        e.stopPropagation();
        this.closeSessionFilters();
      }
    });
  }

  updateCalendarResults(event) {
    const [data, _, xhr] = event.detail;
    window.history.pushState(null, null, xhr.responseURL);
    this.tearDown();
    document.querySelector('#results').innerHTML = data;
    this.setup();
  }

  setupOneTimeHandlers() {
    const selectors = ['form#modal_search', 'form#coach_search', 'a#clear-filters', 'form.reserve-session'];
    const updateCalendarResults = this.updateCalendarResults.bind(this);

    document.addEventListener("ajax:success", (event) => {
      const match = selectors.some((selector) => {
        const elements = Array.from(document.querySelectorAll(selector));
        return elements.some((element) => event.target === element);
      });

      if ( match ){
        updateCalendarResults(event);
      }
    });
  }

  handleEscape(e) {
    if (e.key === "Escape") {
      this.closeSessionFilters();
    }
  }

  openSessionFilters() {
    const filters = document.querySelector('#session-filters');
    filters.classList.add('--open');
  }

  closeSessionFilters() {
    const filters = document.querySelector('#session-filters');

    if ( filters.classList.contains('--open') ){
      filters.classList.remove('--open');
      const params = new URLSearchParams(window.location.search);
      params.delete('modal_search');
      window.history.replaceState(null, null, `?${  params.toString()}`);
    }
  }

  tearDown() {
    if ( this.glide ) this.glide.destroy();
  }
}
