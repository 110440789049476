import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('.off-screen').forEach((checkbox) => {
      checkbox.addEventListener('change', () => {
        Rails.fire(this.element, 'submit');
      });
    });
  }
}
