import { openBasicModal } from './modal';

export const bulkRemoval = (form, committedDays) => {
  const hiddenFields = form.querySelector('.hidden_fields');
  const summaryWrapper = form.querySelector('.summary');

  const clearHiddenFields = () => {
    hiddenFields.innerHTML = '';
  };

  const appendCommittedDayID = (id) => {
    let hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'committed_day_ids[]');
    hiddenField.setAttribute('value', id);
    hiddenFields.appendChild(hiddenField);
  };

  const appendCommittedDays = () => {
    committedDays.forEach((committedDay) => {
      appendCommittedDayID(committedDay.id);
    });
  };

  const defaultCommittedDayHash = (committedDay) => {
    return {
      breakdown: committedDay.breakdown,
      totalCount: 0
    };
  };

  const groupedByDayType = (() => {
    let groups = {};

    committedDays.forEach((committedDay) => {
      const hash = groups[committedDay.type_hash] || defaultCommittedDayHash(committedDay);
      hash.totalCount += 1;
      groups[committedDay.type_hash] = hash
    });

    return Object.values(groups);
  })();

  const updateSummary = () => {
    const summary = groupedByDayType.map((group) => {
      return `<li>${group.totalCount} x ${group.breakdown}</li>`;
    });

    summaryWrapper.innerHTML = `<ul>${summary.join("\n")}</ul>`;
  };

  const remove = () => {
    updateSummary();
    clearHiddenFields();
    appendCommittedDays();

    openBasicModal('remove-committed-days-modal');
  };

  return {
    remove: remove
  };
};
