import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "change", "form" ];

  connect() {
    $(this.formTarget).slideUp(0);
  }

  change(e) {
    e.preventDefault();
    this.formTarget.querySelector('select').value = Intl.DateTimeFormat().resolvedOptions().timeZone;
    $(this.formTarget).slideToggle();
  }
}
