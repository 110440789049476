import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['discardButton', 'submitButton'];

  declare discardButtonTarget?: HTMLButtonElement;
  declare hasDiscardButtonTarget: boolean;
  declare submitButtonTarget: HTMLInputElement;
  declare dirty: boolean;
  declare boundPreventDirtyUnload: (event: Event) => any;

  connect() {
    this.dirty = false;
    this.boundPreventDirtyUnload = this.preventDirtyUnload.bind(this);
    window.addEventListener('beforeunload', this.boundPreventDirtyUnload);
  }

  disconnect() {
    document.removeEventListener('beforeunload', this.boundPreventDirtyUnload);
  }

  markDirty() {
    this.dirty = true;
    if (this.hasDiscardButtonTarget) {
      this.discardButtonTarget?.removeAttribute('disabled');
    }
    this.submitButtonTarget.removeAttribute('disabled');
  }

  removeDirtyFlag() {
    this.dirty = false;
  }

  discardChanges(event: Event) {
    event.preventDefault();
    if (!this.dirty) {
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm('Are you sure you want to discard your changes without saving?');
    if (confirmed) {
      this.dirty = false;
      window.location.reload();
    }
  }

  preventDirtyUnload(event: BeforeUnloadEvent) {
    if (this.dirty) {
      event.returnValue = '';
    } else {
      delete event.returnValue;
    }
  }
}
