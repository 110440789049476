import TomSelect from 'tom-select';

export default function tomSelectInput(targetElement, initialValue){
  if (
    typeof targetElement === 'string' &&
    document.querySelectorAll(targetElement).length === 0
  ) {
    return;
  }
  const select = new TomSelect(targetElement, {
    plugins: ['remove_button'],
  });

  if (initialValue) {
    select.setValue(initialValue);
  }
};
