import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['managerSpecificFieldsWrapper', 'managerSpecificFieldInput', 'managerQuestionFieldInput'];

  declare managerSpecificFieldsWrapperTarget: HTMLDivElement;
  declare managerSpecificFieldInputTargets: HTMLInputElement[];
  declare managerQuestionFieldInputTargets: HTMLInputElement[];

  connect() {
    const currentSelection = this.managerQuestionFieldInputTargets.find((el) => el.checked);
    if (currentSelection) {
      this.toggleManagerSpecificFields(currentSelection.value);
    }
  }

  managerRadioToggle(event: Event) {
    const currentValue = (event.currentTarget as HTMLInputElement).value;
    this.toggleManagerSpecificFields(currentValue);
  }

  toggleManagerSpecificFields(managerFieldValue: string) {
    switch (managerFieldValue) {
      case 'true':
        this.enableManagerSpecificFields();
        break;
      case 'false':
        this.disableManagerSpecificFields();
        break;
      default:
    }
  }

  enableManagerSpecificFields() {
    this.managerSpecificFieldInputTargets.forEach((el) => {
      el.disabled = false;
    });
    this.managerSpecificFieldsWrapperTarget.classList.remove('hidden');
  }

  disableManagerSpecificFields() {
    this.managerSpecificFieldInputTargets.forEach((el) => {
      el.disabled = true;
    });
    this.managerSpecificFieldsWrapperTarget.classList.add('hidden');
  }
}
