import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.navIcon = this.element.querySelector('.nav-icon') || this.element.querySelector('.welcome-nav-icon');
    this.menuClosedLabel = this.element.querySelector('#menu-closed-label');
    this.menuOpenLabel = this.element.querySelector('#menu-open-label');
    this.mobileMenu = document.querySelector('.mobile-menu');
    $(this.menuOpenLabel).hide();
  }

  toggleMenu(e) {
    e.preventDefault();
    this.navIcon.classList.toggle('open');
    this.mobileMenu.classList.toggle('hidden');
    $(this.menuClosedLabel).toggle();
    $(this.menuOpenLabel).toggle();
  }
}
