import { Controller } from '@hotwired/stimulus';
import { GoogleCharts } from 'google-charts';
import displayFlash from "../utils/displayFlash";

export default class extends Controller {
  static targets = ['chartDiv', 'chartData']
  static values = {
    chartData: Array,
    chartOptions: Object,
    chartPresentAs: String
  }

  initialize() {
    this.chartImageUrl = undefined;
  }

  connect() {
    GoogleCharts.load(this.drawChart.bind(this));
  }

  drawChart() {
    const data = GoogleCharts.api.visualization.arrayToDataTable(this.chartDataValue)
    const options = this.chartOptionsValue;

    const chartMapping = {
      bar_chart: GoogleCharts.api.visualization.BarChart,
      pie_chart: GoogleCharts.api.visualization.PieChart,
      column_chart: GoogleCharts.api.visualization.ColumnChart,
      combo_chart: GoogleCharts.api.visualization.ComboChart,
    };

    const ChartFunction = chartMapping[this.chartPresentAsValue];
    const chart = new ChartFunction(this.chartDivTarget);

    GoogleCharts.api.visualization.events.addListener(
      chart,
      'ready',
      () => {
        this.chartImageUrl = chart.getImageURI();
      }
    );

    chart.draw(data, options);
  }

  copyBlobToClipboard(blob) {
    navigator.clipboard
      .write([
        new window.ClipboardItem({
          [blob.type]: blob,
        }),
      ])
      .then(() => {
        displayFlash('Copied to clipboard', 2000);
      });
  }

  copyChartToClipboard() {
    fetch(this.chartImageUrl)
      .then((res) => res.blob())
      .then(this.copyBlobToClipboard);
  }
}
