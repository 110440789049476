import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const menuButton = this.element;

    $(menuButton).on('click', function(){
      $('.context-menu').not(menuButton).removeClass('--open');
      $(this).toggleClass('--open');
    });
  }
}
