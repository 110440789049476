import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['headline', 'bio'];

  declare headlineTarget: HTMLTextAreaElement;
  declare bioTarget: HTMLTextAreaElement;

  validateTextLength(el: HTMLTextAreaElement) {
    const maxLength = Number(el.dataset.allowedlength) || 110;

    if (el.value.split(" ").length > maxLength) {
      el.setCustomValidity(`You have exceeded the limit of ${maxLength} words`);
    } else {
      el.setCustomValidity('');
    }

    el.reportValidity();
  }

  validateTextLengthHandler(event: Event) {
    const currentEl = event.currentTarget as HTMLTextAreaElement;
    this.validateTextLength(currentEl);
  }
}
