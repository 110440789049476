import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['actions'];
  static values = { isOpen: Boolean };

  connect() {
    this.hideButtons();
  }

  open(e) {
    if ( this.isOpenValue ) {
      return;
    }

    e.preventDefault();

    if ( !this.element.classList.contains('--booked') ){
      this.element.classList.toggle('--open');
      $(this.actionsTarget).animate({width: 'toggle'}, 300);
    }

    this.isOpenValue = true;
  }

  close(e) {
    e.preventDefault();
    e.stopPropagation();
    this.element.classList.remove('--open');
    $(this.actionsTarget).animate({width: 'toggle'}, 300);
    this.isOpenValue = false;
  }

  hideButtons() {
    $(this.actionsTarget).animate({width: 'toggle'}, 0);
  }
}
