import { Controller } from "@hotwired/stimulus"
import { bulkRemoval } from '../bulkRemoval';
import bulkCancellation from '../bulkCancellation';

export default class extends Controller {
  initialize() {
    this.selected = [];
    this.validActions = [];
    this.removeForm = document.querySelector('#remove-committed-day-form');
    this.cancelForm = document.querySelector('#cancellation-confirmation-modal');
  }

  connect() {
    this.setActionLinks();
  }

  bulkRemove(event) {
    event.preventDefault();

    if ( !this.validActions.includes('remove') ) return;

    bulkRemoval(this.removeForm, this.selected).remove();
  }

  bulkCancel(event) {
    event.preventDefault();

    if ( !this.validActions.includes('cancel') ) return;

    bulkCancellation(this.cancelForm, this.selected).cancel();
  }

  nextScreen(event) {
    event.preventDefault();

    bulkCancellation(this.cancelForm, this.selected).nextScreen();
  }

  toggleAll(event) {
    const {checked} = event.target;
    const checkboxes = this.element.querySelectorAll('.result-list__row.committed-day input[type="checkbox"]');

    checkboxes.forEach((checkbox) => {
      if ( checkbox.checked !== checked ){
        checkbox.checked = checked;
        checkbox.dispatchEvent(new Event('change', { 'bubbles': true }));
      }
    });
  }

  disableActionLink(link) {
    link.classList.add('--disabled');
  }

  enableActionLink(link) {
    link.classList.remove('--disabled');
  }

  setActionLinks() {
    document.querySelectorAll('.links-list a').forEach((link) => {
      const action = link.dataset.bulkAction;
      if ( this.validActions.includes(action) ){
        this.enableActionLink(link);
      } else {
        this.disableActionLink(link);
      }
    });
  }

  toggleSingle(event) {
    const committedDay = JSON.parse(event.target.dataset.committedDay);

    if ( event.target.checked ){
      this.selected.push(committedDay);
    } else {
      this.selected = this.selected.filter((selected) => selected.id !== committedDay.id);
    }

    const actions = ( this.selected.length > 0 ) ? new Set(['cancel', 'remove']) : new Set();

    this.selected.forEach((day) => {
      if ( !day.isCancellable ){
        actions.delete('cancel');
      }

      if ( !day.isRemovable ){
        actions.delete('remove');
      }
    });

    this.validActions = Array.from(actions);
    this.setActionLinks();
  }
}
