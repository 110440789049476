import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'upcomingEventMessage',
    'liveEventMessage',
    'eventVideoLink',
    'preSessionContainer',
    'postSessionContainer'
  ];

  static values = {
    videoUrl: String,
    eventOpensAt: String,
  };

  connect() {
    this.checkEventLink();
    this.startTimer();
  }

  disconnect() {
    clearInterval(this.timer);
  }

  startTimer() {
    if (this.eventVideoLinkTarget.classList.contains('--disabled')) {
      this.timer = setInterval(() => {
        this.checkEventLink();
      }, 10000);
    }
  }

  checkEventLink() {
    if (Date.now() >= Date.parse(this.eventOpensAtValue)) {
      this.enableVideoLink();
    }
  }

  enableVideoLink() {
    this.eventVideoLinkTarget.classList.remove('--disabled');
    this.upcomingEventMessageTarget.classList.add('hidden');
    this.liveEventMessageTarget.classList.remove('hidden');
    clearInterval(this.timer);
  }

  ctaButtonClick() {
    if (this.eventVideoLinkTarget.classList.contains('--disabled')) {
      return;
    }
    this.openVideoUrl();
    this.preSessionContainerTarget.classList.add('hidden');
    this.postSessionContainerTarget.classList.remove('hidden');
  }

  openVideoUrl() {
    const zoomWindow = window.open(this.videoUrlValue, 'Your Launch Session');
    // Safari iOS (and others?) won't open new window if the popups are blocked
    if (zoomWindow) {
      zoomWindow.focus();
    } else {
      window.location = this.videoUrlValue;
    }
  }
}
