import { Controller } from "@hotwired/stimulus"
import { closeBasicModal } from '../modal';

export default class extends Controller {
  initialize(){
    this.closeModal = closeBasicModal;
  }

  close(event) {
    if ( event.target === event.currentTarget ){
      this.closeModal();
    }
  }

  exit() {
    this.element.classList.remove('--open');
  }
}
