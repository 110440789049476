import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["profile", "loadMoreButton"]

  declare profileTargets: HTMLElement[];
  declare loadMoreButtonTarget: HTMLElement;

  perPage = parseInt((this.data.get("perPageNumber") || "3"))
  hiddenCSSClass = '!hidden'

  loadMoreProfiles(event: Event) {
    event.preventDefault()
    this.showNextPageOfProfiles()
    if(this.noMoreProfiles) { this.hideLoadMoreButton() }
  }

  showNextPageOfProfiles() {
    this.nextPageOfProfiles.forEach(el => this.show(el))
  }

  get nextPageOfProfiles() {
    return this.hiddenProfiles.slice(0, this.perPage)
  }

  get hiddenProfiles() {
    return this.profileTargets.filter(el => el.classList.contains(this.hiddenCSSClass))
  }

  get noMoreProfiles() {
    return this.nextPageOfProfiles.length === 0
  }

  hideLoadMoreButton() {
    this.loadMoreButtonTarget.classList.add(this.hiddenCSSClass)
  }

  show(e: HTMLElement) { e.classList.remove(this.hiddenCSSClass) }
}
