import { Controller } from "@hotwired/stimulus"

interface SimpleErrorResponse {
  errors: string[]
}

interface AjaxSuccessEvent<T = any> extends CustomEvent {
  detail: [data: T, status: XMLHttpRequest['statusText'], xhr: XMLHttpRequest];
}

export default class extends Controller {
  static targets = [ "errors" ];
  declare errorsTarget: HTMLDivElement;

  connect() {
    this.hideErrors();
  }

  hideErrors() {
    this.errorsTarget.style.display = "none";
  }

  onError(response: AjaxSuccessEvent<SimpleErrorResponse>) {
    const [data, status, xhr] = response.detail;
    const messages = data.errors;
    const error = messages[0] ?? 'Error occurred. Please contact us at bookings@sanctus.io'
    this.errorsTarget.innerHTML = error;
    this.errorsTarget.style.display = "block";
  }
}
