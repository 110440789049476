import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if ( window.innerWidth > 800 ){
      this.setupCarousel();
    }
  }

  setupCarousel() {
    this.items = Array.from(this.element.querySelectorAll('.carousel-item'));
    const notes = document.querySelectorAll('.carousel-notes');
    notes.forEach((element) => element.style.display = "none");
    notes[0].style.display = "block";
  }

  focus(e) {
    const clicked = e.currentTarget;

    document.querySelectorAll('.carousel-notes').forEach((element) => element.style.display = "none");
    document.querySelector(`#${clicked.dataset.notesId}`).style.display = "block";

    const index = this.items.indexOf(clicked);
    this.applyClasses(clicked, '--focused');
    this.applyClasses(this.prev(index), '--left');
    this.applyClasses(this.next(index), '--right')
  }

  next(index) {
    let number = index + 1;
    number = number > 2 ? 0 : number;

    return this.items[number];
  }

  prev(index) {
    let number = index - 1;
    number = number < 0 ? 2 : number;

    return this.items[number];
  }

  applyClasses(element, cssClass) {
    element.classList.remove("--left", "--focused", "--right");
    element.classList.add(cssClass);
  }
}
