import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const formContainer = this.element.querySelector('#magic-link-form');
    const feedbackContainer = this.element
      .querySelector('#magic-link-feedback');
    formContainer.style.display = 'block';
    feedbackContainer.style.display = 'none';

    formContainer.querySelector('form')
      .addEventListener('ajax:success', (event) => {
        formContainer.style.display = 'none';
        feedbackContainer.style.display = 'block';
        const emailElement = document.getElementById('email');
        if (emailElement !== null) {
          document.getElementById('magic-link-form-email-display').textContent = document.getElementById('email').value || '';
        };
        this.element.dispatchEvent(
          new Event('magicLinkForm:submitted', { bubbles: true }),
        );
      });
  }
}
