import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['width'];

  connect() {
    this.container = $(this.element.querySelector('.day-sessions'));
    this.enabled = window.innerWidth < 768;
    this.setup();
  }

  setup() {
    if ( this.enabled ){
      this.container.slideUp(0);
      $(this.element).addClass('--closed');
    }
  }

  toggle(e) {
    e.preventDefault();
    this.container.slideToggle();
    $(this.element).toggleClass('--closed');
  }
}
