import { openBasicModal } from './modal';

export default function bulkCancellation(parent, committedDays) {
  const hiddenFields = parent.querySelector('.hidden_fields');
  const summaryWrapper = parent.querySelector('.cancellation-modal__summary');
  const dateConfirmationScreen = parent.querySelector('#confirm-dates-screen');
  const cancelConfirmationScreen = parent.querySelector('#confirm-cancellation-screen');
  const reviewDatesList = parent.querySelector('.review-dates');

  const clearHiddenFields = () => {
    hiddenFields.innerHTML = '';
  };

  const appendBookingID = (id) => {
    const hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'booking_ids[]');
    hiddenField.setAttribute('value', id);
    hiddenFields.appendChild(hiddenField);
  };

  const appendCommittedDays = () => {
    committedDays.forEach((committedDay) => {
      appendBookingID(committedDay.booking_id);
    });
  };

  const showDateConfirmation = () => {
    dateConfirmationScreen.style.display = 'block';
    cancelConfirmationScreen.style.display = 'none';

    const dateList = committedDays.map(
      (committedDay) => `<li>${committedDay.bookingDate} &bull; ${committedDay.coachName}</li>`
    );

    summaryWrapper.innerHTML = `This action will cancel ${committedDays.length} coaching days.`;
    reviewDatesList.innerHTML = `<ul class="dates-list">${dateList.join('\n')}</ul>`;
  };

  const showCancelConfirmation = () => {
    dateConfirmationScreen.style.display = 'none';
    cancelConfirmationScreen.style.display = 'block';
    const committedDay = committedDays[0];
    summaryWrapper.innerHTML = committedDay.hint;
  };

  const updateSummary = () => {
    summaryWrapper.innerHTML = '';
    reviewDatesList.innerHTML = '';

    if (committedDays.length > 1) {
      showDateConfirmation();
    } else {
      showCancelConfirmation();
    }
  };

  const cancel = () => {
    updateSummary();
    clearHiddenFields();
    appendCommittedDays();

    openBasicModal('cancel-coaching-days-modal');
  };

  const nextScreen = () => {
    dateConfirmationScreen.style.display = 'none';
    cancelConfirmationScreen.style.display = 'block';
  };

  return {
    cancel,
    nextScreen,
  };
}
