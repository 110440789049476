import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    threshold: Number
  };

  connect() {
    if ( 'IntersectionObserver' in window ){
      const observer = this.setupObserver();
      observer.observe(this.element);
    }
  }

  setupObserver() {
    return new IntersectionObserver(function(entries){
      entries.forEach((entry) => {
        if ( entry.isIntersecting ){
          mixpanel.track('Viewed Element', { element_id: entry.target.id });
        }
      });
    }, { threshold: this.thresholdValue });
  }
}
