import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "cancelLink", "stateFlag" ];

  connect() {
    const stateFlag = this.stateFlagTarget;
    const contextMenu = this.element.querySelector('.context-menu');

    if ( this.hasCancelLinkTarget ){
      this.cancelLinkTarget.addEventListener("ajax:success", (event) => {
        stateFlag.classList.add('search-result__flag--cancelled');
        stateFlag.textContent = 'Cancelled';
        contextMenu.style.display = "none";
      });
    }
  }
}
