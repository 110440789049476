import Rails from '@rails/ujs';

export const performSearch = ($form) => {
  Rails.fire($form[0], 'submit');
};

export const openModal = (modalId) => {
  const $modal = $('#' + modalId);
  const $form = $modal.find('form');
  performSearch($form);
  $modal.addClass('modal--open');
  $(document).keyup(closeOnEscape);
};

export const closeModal = () => {
  $('.modal').removeClass('modal--open');
};

export const closeOnEscape = (e) => {
  if (e.key === 'Escape') {
    closeModal();
  }
};

export const openBasicModal = (id) => {
  $('#' + id).addClass('fullscreen-modal--open');
};

export const closeBasicModal = () => {
  $('.fullscreen-modal').removeClass('fullscreen-modal--open');
};

$(document).ready(function(){
  $("[data-modal-trigger]").click(function(){
    const modalId = $(this).data('modal-id');
    openModal(modalId);
  });

  $('.modal-close').click(function(e){
    if ( e.target.classList.contains('modal') ){
      closeModal();
      $(document).unbind('keyup', closeOnEscape);
    }
  });
});
