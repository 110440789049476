import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.modal = this;
    document.addEventListener("turbo:before-cache", this.close.bind(this));
  }

  closeOnEscape = (e) => {
    if (e.key === 'Escape') {
      this.close();
    }
  };

  open() {
    this.element.classList.add('--open');
    document.addEventListener('keyup', this.closeOnEscape);
    document.body.style.overflow = "hidden";
  }

  close() {
    this.element.classList.remove('--open');
    document.removeEventListener('keyup', this.closeOnEscape);
    document.body.style.overflow = "auto";
  }

  handleBackgroundClick(e) {
    if ( e.target === e.currentTarget ){
      this.close();
    }
  }
}
