import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.processTrackingEvents = this.processTrackingEvents.bind(this);
  }

  connect() {
    document.addEventListener("ajax:complete", this.processTrackingEvents);
  }

  disconnect() {
    document.removeEventListener("ajax:complete", this.processTrackingEvents);
  }

  track_event(e) {
    const event = JSON.parse(e.currentTarget.dataset.event);
    event.properties = { ...event.properties, ...{ time: Date.now() / 1000 } };
    mixpanel.track(event.name, event.properties);
  }

  track_selected(e) {
    if ( e.currentTarget.checked ){
      const event = JSON.parse(e.currentTarget.dataset.event);
      mixpanel.track(event.name, event.properties);
    }
  }

  processTrackingEvents(event) {
    const [xhr, status] = event.detail;
    const contentType = event.detail[0].getResponseHeader('content-type');

    if ( xhr.responseText && contentType.includes('application/json') ) {
      const json = JSON.parse(xhr.responseText);
      const events = json.events || [];

      events.forEach((event) => {
        mixpanel.track(event.name, event.properties);
      });
    }
  }
}
