import NewModalController from "./new_modal_controller";

export default class extends NewModalController {
  static targets = ['reservationConfirm', 'reservationCancel'];

  connect() {
    super.connect();
    this.open();
    this.reservationSubmitted = false;
    this.element.addEventListener('magicLinkForm:submitted', () => {
      this.reservationSubmitted = true;
    });
  }

  close(e) {
    if (!this.reservationSubmitted) {
      e.preventDefault();
      this.showCancellationScreen();
    } else {
      super.close();
    }
  }

  cancel(){
    super.close();
  }

  showCancellationScreen() {
    this.reservationConfirmTarget.classList.add('hidden');
    this.reservationCancelTarget.classList.remove('hidden');
    document.querySelector('.--reservation-modal #closeModal').classList.add('hidden');
  }

  showConfirmationScreen() {
    this.reservationConfirmTarget.classList.remove('hidden');
    this.reservationCancelTarget.classList.add('hidden');
    document.querySelector('.--reservation-modal #closeModal').classList.remove('hidden');
  }
}
